import BaseModel from "./base";

class Auth extends BaseModel {
  /**
     * Login user for application
     *
     * @param {string} email
     * @param {string} password
\      @returns @member any
     */
  login(email: string, password: string){
    return this.http().post(`${this.baseUrl}/auth/login`, {email, password}).then(this.resolve).catch(this.reject);
  }

  getInviteSignup(inviteGuid: string){
    return this.http().get(`${this.baseUrl}/auth/invite?id=${inviteGuid}`).then(this.resolve).catch(this.reject);
  }

  isUserLoggedIn(){
    return this.http().post(`${this.baseUrl}/auth/is-user-logged-in`, {}).then(this.resolve).catch(this.reject);
  }

  forgotPassword(email: string) {
    return this.http().post(`${this.baseUrl}/auth/forgot-password`, {email}).then(this.resolve).catch(this.reject);
  }

  welcomeNewStaffMember(email: string) {
    return this.http().post(`${this.baseUrl}/welcome-staff`, {email}).then(this.resolve).catch(this.reject);
  }

  resetPassword(password, confirmPassword, token, email){
    return this.http().post(`${this.baseUrl}/auth/reset-password`, { password, password_confirmation: confirmPassword, id: token, email }).then(this.resolve).catch(this.reject)
  }

  resetOwnPassword(password){
    return this.http().post(`${this.baseUrl}/auth/reset-password`, password).then(this.resolve).catch(this.reject)
  }

  refreshOrganizationToken(organizationId){
    return this.http().post(`${this.baseUrl}/organization/switch-organization`, {organization_id: organizationId}).then(this.resolve).catch(this.reject)
  }

  requestAccess(){
    return this.http().post(`${this.baseUrl}/organization/request-access-to-workspaces`).then(this.resolve).catch(this.reject)
  }

  validatePasswordResetToken(token, email){
    return this.http().post(`${this.baseUrl}/auth/validate-reset-token/${token}?email=${email}`).then(this.resolve).catch(this.reject)
  }

}

export default new Auth();