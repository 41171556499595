import Axios, {
  AxiosInstance,
  AxiosResponse,
  AxiosError,
  AxiosRequestConfig,
} from "axios"
import AuthUtil from "@utils/auth"
import _ from "lodash"
import { GetConfig, UpdateConfig } from "@context/context"
import { IAppContext } from "../definitions/interfaces"
import { navigate } from "gatsby"

console.log('USING API_URL ' + process.env.GATSBY_API_URL, 'staging:', process.env.STAGING)

export default class BaseModel {
  instance: AxiosInstance
  organizationUnitId: number | undefined
  baseUrl: string | undefined
  authToken: string | undefined
  config: IAppContext
  refreshToken: string | undefined
  organizationId?: number
  authFailCount: number = 4

  constructor() {
    //add some of the config properties to this class so they can
    //be accessed easily for classes extending base.
    this.config = GetConfig()
    this.organizationId = this.config.organizationId
    this.baseUrl = this.config.apiUrl
    this.instance = Axios.create({
      baseURL: this.config.apiUrl,
    })

    this.instance.interceptors.response.use(
      response => {
        return response
      },
      error => {
        const originalRequest = error.config
        const self = this // Save the context
        const isLoginRoute = (url: string) => {
          return url.includes("auth") && url.includes("login")
        }

        if (isLoginRoute(originalRequest.url)) {
          return Promise.reject(error.response?.data || error)
        }

        if (error.response.status === 401 && self.authFailCount < 5) {
          self.authFailCount++
          originalRequest._retry = true
          const refreshToken = AuthUtil.getRefreshToken()
          if (!refreshToken) {
            self.handleAuthError()
            return Promise.reject(error.response?.data || error)
          }

          return self
            .http()
            .post(`${self.baseUrl}/auth/refresh-token`, {
              refresh_token: refreshToken,
            })
            .then(userData => {
              UpdateConfig("token", userData.data.token)
              UpdateConfig("refreshToken", userData.data.refresh_token)

              self.config.token = userData.data.token
              self.config.refreshToken = userData.data.refresh_token

              self.authToken = userData.data.token
              self.refreshToken = userData.data.refresh_token
              self.authFailCount = 0

              AuthUtil.setToken(self.authToken, true)
              AuthUtil.setRefreshToken(self.refreshToken, true)

              // Retry the original request with the new token
              originalRequest.headers[
                "Authorization"
              ] = `Bearer ${self.authToken}`
              return self.instance(originalRequest)
            })
            .catch(() => {
              self.handleAuthError()
              return Promise.reject(error.response?.data || error)
            })
        } else {
          return Promise.reject(error.response?.data || error)
        }
      }
    )

    this.instance.interceptors.request.use((config: AxiosRequestConfig) => {
      if (
        this.config.token &&
        !_.isEmpty(this.config.token) &&
        this.config.token !== "undefined"
      ) {
        config.headers["Authorization"] = `Bearer ${this.config.token}`
      }
      return config
    })
  }

  handleAuthError() {
    AuthUtil.clearToken()
    AuthUtil.clearUserInfo()
    // Get the current path and query string
    const currentPath = window.location.pathname.substr(1)
    const currentQuery = window.location.search

    // Only redirect to login if the current path is not 'login'
    if (currentPath !== "login") {
      const encodedPath = encodeURIComponent(currentPath)
      const encodedQuery = encodeURIComponent(currentQuery)
      navigate(`/login?path=${encodedPath}&location=${encodedQuery}`)
    }

    this.config.user = null
  }

  setTokens(token: string) {
    this.config.token = token

    this.instance.interceptors.request.use((config: AxiosRequestConfig) => {
      if (
        this.config.token &&
        !_.isEmpty(this.config.token) &&
        this.config.token !== "undefined"
      ) {
        config.headers["Authorization"] = `Bearer ${this.config.token}`
      }

      return config
    })
  }

  http() {
    return this.instance
  }

  resolve(response: AxiosResponse) {
    return response.data?.result ? response.data?.result : response.data
  }

  reject(error: AxiosError) {
    if (error?.response?.status === 409) {
      throw new Error("This shift time conflicts with an existing shift")
    }

    if (!error.response && error.message) {
      throw new Error(error.message)
    }

    if (error.response && error.response.data && error.response.data.message) {
      throw error.response.data
    } else {
      throw new Error(JSON.stringify(error))
    }
  }
}
