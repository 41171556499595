import BaseModel from "./base";

export interface ISettingsInputDto {
    settings: string;
    public_holidays: string;
    dashboard: string;
    checkins_force_signout_time: string;
}

class Settings extends BaseModel {
    get() {
        return this.http().get(`${this.baseUrl}/settings`).then(this.resolve).catch(this.reject);
    }

    update(settings: string) {
        return this.http().put(`${this.baseUrl}/settings`, { settings }).then(this.resolve).catch(this.reject);
    }

    save(settingsInput: Partial<ISettingsInputDto>) {
        return this.http().put(`${this.baseUrl}/settings`, settingsInput).then(this.resolve).catch(this.reject);
    }

    updateReviewSettings(performance_review_settings: string) {
        return this.http().put(`${this.baseUrl}/settings`, { performance_review_settings }).then(this.resolve).catch(this.reject);
    }

    create(settings: string) {
        return this.http().post(`${this.baseUrl}/settings`, { settings }).then(this.resolve).catch(this.reject);
    }

    updateLibrarySettings(settings: string) {
        return this.http().put(`${this.baseUrl}/settings/library`, { settings }).then(this.resolve).catch(this.reject);
    }

}

export default new Settings();
