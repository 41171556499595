import React from "react";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import './src/content/css/tailwind/index.css';
import AuthModel from './src/models/auth';
import User from './src/models/user';
import { Alert, AlertColor, Snackbar } from "@mui/material";
import { AppContext, InitializeConfig, AppConfig, UpdateConfig } from './src/context/context';
import { IAppContext, IAppSettings, ILastReadTime } from './src/definitions/interfaces';
import auth from "./src/utils/auth";
import { LastReadTimeContext, PubSubEvent } from "./src/definitions/enums";
import Pubsub from 'pubsub-js';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './src/content/css/app.scss';
import { primaryColor } from './src/constants';
import { NotificationPosition } from "./src/utils/notifications";
import SettingsModel from './src/models/settings';
import lastReadTimes from "./src/models/last-read-times";
import _ from "lodash";
import SubscriptionPlanBanner from "./src/components/subscription/banner";
import { parseUserPreferences } from "./src/utils/user";
import { parseAppSettings } from "./src/services/settings";
interface Props {
    children: JSX.Element | JSX.Element[];
}

interface State {
    appContext: IAppContext;
    isLoading: boolean;
    snackbarOpen: boolean;
    notification: string;
    notificationType?: AlertColor;
    stripeOptions?: any;
    bootstrapped: boolean;
    stripeInstance?: any;
    notificationPosition?: NotificationPosition;
    gettingSettings: boolean;
    isSubscriptionActive: boolean;
}

class ContextProvider extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            appContext: AppConfig,
            isLoading: true,
            snackbarOpen: false,
            notification: '',
            bootstrapped: false,
            gettingSettings: false,
            isSubscriptionActive: false
        };

    }
    async componentDidMount() {
        this.bootStrap();
        this.subScribeToPubsubEvents();
        this.setScreenSize();
        this.subscribeToInstallPWAPrompt();
        this.subscribeToScreenSizeChanges();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', () => {
            this.setScreenSize();
        });
        Pubsub.unsubscribe(PubSubEvent.GetSettings);
        Pubsub.unsubscribe(PubSubEvent.GetLastReadTimes);
        Pubsub.unsubscribe(PubSubEvent.Notification);
    }

    get snackBarAnchorOrigin(): { vertical: 'top' | 'bottom', horizontal: 'left' | 'center' | 'right' } {
        return { vertical: 'bottom', horizontal: 'right' };
    }
    

    subscribeToScreenSizeChanges() {
        window.addEventListener('resize', () => {
            this.setScreenSize();
        });
    }

    subscribeToInstallPWAPrompt() {
        let deferredPrompt;

        window.addEventListener('beforeinstallprompt', (e) => {
            // Prevent Chrome 67 and earlier from automatically showing the prompt
            e.preventDefault();
            // Stash the event so it can be triggered later.
            deferredPrompt = e;
            // Update UI to notify the user they can add to home screen
            showInstallPrompt();
        });

        async function showInstallPrompt() {
            if (deferredPrompt) {
                deferredPrompt.prompt();
                deferredPrompt.userChoice.then(choiceResult => {
                    if (choiceResult.outcome === 'accepted') {
                        console.log('User accepted the install prompt');
                    } else {
                        console.log('User dismissed the install prompt');
                    }
                    deferredPrompt = null;
                });
            }
        }
    }

    subScribeToPubsubEvents() {
        Pubsub.subscribe(PubSubEvent.GetSettings, (event, data) => {
            
            this.getSettings(data?.callback);
            this.getUserPreferences();
        });

        Pubsub.subscribe(PubSubEvent.GetLastReadTimes, () => {
            this.getUserLastReadTimes();
        });

        Pubsub.subscribe(PubSubEvent.Notification, (e: any, message: { notification: string, type: AlertColor, position: NotificationPosition; }) => {
            this.setState({
                notification: message.notification,
                notificationType: message.type,
                snackbarOpen: true,
                notificationPosition: message.position || { vertical: 'top', horizontal: 'right' }
            });

        });
    }

    getUserLastReadTimes() {
        if (!this.state.appContext?.user?.staff_user?.id) {
            lastReadTimes.getAll(this.state.appContext?.user?.staff_user?.id as number).then((lastReadTimes) => {
                this.updateAppContext('lastReadTimes', lastReadTimes);
            }).catch((err) => console.log(err));
        }
    }

    setUserLastReadTime(context: LastReadTimeContext) {
        const staff_user_id = this.state.appContext?.user?.staff_user?.id;

        if (staff_user_id && context) {
            lastReadTimes.update(staff_user_id, context, { last_read_time: new Date() } as ILastReadTime).then(() => {
                this.getUserLastReadTimes();
            }).catch((err) => {
                console.error(err);
            });
        }
    }

    setScreenSize() {
        const screenSize = {
            width: window.innerWidth,
            height: window.innerHeight
        };
        this.updateAppContext('screenSize', screenSize);
        PubSub.publish(PubSubEvent.ScreenSizeChanged, screenSize);
    }

    async getSettings(callback?: (settings: IAppSettings) => void) {
        if (auth.getUserInfo() == null && auth.getToken() == null) {
            return;
        }
        this.setState({ gettingSettings: true });
        const settingsDto = await SettingsModel.get().catch((err) => {
            console.error('Failed to load settings', err);
        });
        
        const settings = await parseAppSettings(settingsDto);
        this.updateAppContext('settings', settings);

        PubSub.publish(PubSubEvent.SettingsLoaded, settings);

        // we can use an optional callback to know when settings are in context, we will add a timeout just in case
        if (callback) {
            setTimeout(() => {
                this.setState({ gettingSettings: false }, () => callback(settings));
            }, 10);
        }
    }

    async bootStrap() {
        const config = await InitializeConfig();
        // if the user has a token and user info saved - check the validity of the token
        

        if (auth.getUserInfo() !== null && auth.getToken() !== null) {
            await AuthModel.isUserLoggedIn().then(async () => {
                if (auth.getUserInfo() !== null) {
                    this.updateAppContext('user', auth.getUserInfo());
                }
            }).catch((err) => {
                auth.clearToken();
                auth.clearUserInfo();
                this.updateAppContext('user', null);
            });
        }

        else {
            this.setState({ bootstrapped: true });
            // return;
        }
        // Get sidebar pref from localstorage
        const sidebarOpen = auth.get('sidebarOpen') === 'true' ? true : false ?? true;
        this.updateAppContext('sidebarOpen', Boolean(sidebarOpen));
        await this.getSettings();
        await this.getUserPreferences();

        this.setState({
            bootstrapped: true,
            appContext: {
                ...config,
                sidebarOpen,
                updateAppContext: (key: (keyof IAppContext), value: any, callback?: Function) => {
                    UpdateConfig(key, value);
                    this.updateAppContext(key, value, callback);
                },
            },

        });

    }

    async getUserPreferences(): Promise<void>{
        if (auth.getUserInfo() == null || auth.getToken() == null) {
            return;
        }
        await User.getUserPreferences().then((preferences) => {
            const userPreferences = parseUserPreferences(preferences); 
            this.updateAppContext('userPreferences', userPreferences);
        }).catch(()=> {
            return;
        });
    }

    updateAppContext(key: (keyof IAppContext), value: any, callback?: Function) {
        let appContext = this.state.appContext;
        appContext[key] = value;
        this.setState({ appContext }, () => {
            if (callback) {
                callback();
            }
        });
    }

    handleCloseSnackBar() {
        this.setState({ snackbarOpen: false });
    }

    render() {
        if (!this.state.bootstrapped) {
            return null;
        }
        
        return (
            <React.Fragment>
                <Snackbar anchorOrigin={this.snackBarAnchorOrigin} open={this.state.snackbarOpen} autoHideDuration={5000} onClose={() => this.handleCloseSnackBar()}>
                    <Alert
                        elevation={6}
                        onClose={() => this.handleCloseSnackBar()}
                        severity={this.state.notificationType}
                        sx={{ maxWidth: '100%', maxHeight: '300px' }}>
                        <span className="overflow-y">{this.state.notification}</span>
                    </Alert>
                </Snackbar>
                
                {this.state.appContext.user && <SubscriptionPlanBanner context={this.state.appContext}/>}
                
                <AppContext.Provider value={this.state.appContext}>
                    {this.props.children}
                </AppContext.Provider>
            </React.Fragment>
        );
    }
}

const theme = createTheme({
    palette: {
        primary: {
            main: primaryColor,
            contrastText: '#fff'
        },
        success: {
            main: '#10ac84'
        },
        secondary: {
            main: '#0F162E'
        }
    },
    typography: {
        fontFamily: 'Roboto',
        fontSize: 12,
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                    fontSize: '1rem',
                },
                containedSuccess: {
                    color: '#fff', // Set font color to white for success buttons
                },
                disableElevation: true
            },
        },
    },
});

export default ({ element }) => {
    // Instantiating store in `wrapRootElement` handler ensures:
    //  - there is fresh store for each SSR page
    //  - it will be called only once in browser, when React mounts
    return (
        <ContextProvider>
            <ThemeProvider theme={theme}>{element}</ThemeProvider>
        </ContextProvider>
    );
};
